/**
 * Created by Giannis Kallergis on 2018-12-24
 */

import PropTypes from "prop-types";
import styled from "styled-components";
import { lighten } from "polished";

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0.5rem;
  transition: all ${props => props.theme.transitions.normal};
  background: ${props => props.background || "none"};
  &:hover {
    background: ${props => (props.background ? lighten(0.5, props.background) : "none")};
    cursor: pointer;
    transform: translateY(-2px);
  }
  &:focus {
    outline: none;
  }
`;

Button.propTypes = {
  background: PropTypes.string,
};

export const TextButton = styled(Button)`
  font-size: ${props => (props.big ? "1.2rem" : "1rem")};
  color: ${props => props.color || "white"};
  padding: ${props => (props.big ? "0.35rem 1.6rem" : "0.25rem 1.5rem")};
  border-radius: ${props => (props.big ? "1.5rem" : "1rem")};
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: ${props => props.background || props.theme.colors.primary};
  &:hover {
    background: ${props => lighten(0.05, props.background || props.theme.colors.primary)};
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
    fill: white;
  }
`;

TextButton.propTypes = {
  big: PropTypes.bool,
  background: PropTypes.string,
  color: PropTypes.string,
};

TextButton.defaultProps = {
  big: false,
};

export default Button;
