/**
 * Created by Giannis Kallergis on 2018-12-24
 */

const Theme = require("./Theme");

module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "Jakallergis.com Blog", // Navigation and Site Title
  siteTitleAlt: "Jakallergis.com", // Alternative Site title for SEO
  siteUrl: "https://jakallergis.com", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteBanner: "/social/banner.png", // Your image for og:image tag. You can find it in the /static folder
  siteDescription: "Personal thoughts and notes of a software engineer.", // Your site description
  siteKeywords: [
    "software development tips and tutorials",
    "developer thoughts on frameworks",
    "struggles of software engineering",
  ],

  author: "@jakallergis", // Author for schemaORGJSONLD
  siteLogo: "/social/logo.png", // Image for schemaORGJSONLD

  userTwitter: "@jakallergis", // Twitter Username - Optional
  ogSiteName: "Jakallergis.com", // Facebook Site Name - Optional
  ogLanguage: "en_US", // Facebook Language

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: Theme.colors.primary,
  backgroundColor: Theme.colors.bg,

  //Favicons
  favicon: "/favicons/favicon-32x32.png",
  appleTouchIcon: "/favicons/apple-touch-icon.png",
  maskIcon: "/favicons/safari-pinned-tab.svg",
  fav16: "/favicons/favicon-16x16.png",

  // Settings for typography.js
  headerFontFamily: "Open Sans",
  bodyFontFamily: "Open Sans",
  baseFontSize: "18px",

  /** Social Links */
  social: {
    twitter: "https://twitter.com/jakallergis",
    github: "https://github.com/jakallergis",
    linkedIn: "https://linkedin.com/in/gianniskallergis",
    bitBucket: "https://bitbucket.org/jakallergis/",
  },
  slackHook: "https://hooks.slack.com/services/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",
};
