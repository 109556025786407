/**
 * Created by Giannis Kallergis on 2018-12-29
 */

import PropTypes from "prop-types";
import styled from "styled-components";

/** Utilities */
import { media } from "../utils/media";

const Content = styled.article`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  border-radius: 1rem;
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  form {
    p {
      label,
      input {
        display: block;
      }
      input {
        min-width: 275px;
        margin-top: 0.5rem;
      }
      textarea {
        resize: vertical;
        min-height: 150px;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }

  ${props => props.hasHeader && "margin-top: -3rem"};

  @media ${media.tablet} {
    padding: 3rem 3rem;
  }

  @media ${media.phone} {
    padding: 2rem 2rem;
  }
`;

Content.propTypes = {
  hasHeader: PropTypes.bool.isRequired,
};

Content.defaultProps = {
  hasHeader: false,
};

export default Content;
