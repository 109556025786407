/**
 * Created by Giannis Kallergis on 2018-12-24
 */

const colors = {
  primary: "#002e77", // Color for buttons or links
  secondary: "#00738E", // Color for buttons or links
  bg: "white", // Background color
  grey: {
    dark: "rgba(0, 0, 0, 0.9)",
    default: "rgba(0, 0, 0, 0.7)",
    light: "rgba(0, 0, 0, 0.5)",
    ultraLight: "rgba(0, 0, 0, 0.25)",
  },
};

const transitions = {
  normal: "0.5s",
};

const fontSize = {
  small: "0.9rem",
};

const theme = {
  colors,
  transitions,
  fontSize,
};

module.exports = theme;
