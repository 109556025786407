/**
 * Created by Giannis Kallergis on 2019-03-23
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FiTwitter, FiGithub, FiLinkedin } from "react-icons/fi";
import { IoLogoBitbucket } from "react-icons/io";
import { lighten } from "polished";

/** Components */
import Button from "./Button";

/** Utilities */
import config from "../../config/SiteConfig";
import Theme from "../../config/Theme";

const icons = {
  twitter: FiTwitter,
  github: FiGithub,
  linkedIn: FiLinkedin,
  bitBucket: IoLogoBitbucket,
};

const StyledIcon = styled.div`
  svg {
    stroke: ${props => props.stroke};
    fill: ${props => props.fill};
  }
`;

export default class SocialIcon extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(["twitter", "github", "linkedIn", "bitBucket"]),
    fill: PropTypes.string,
    stroke: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    stroke: lighten(0.5, Theme.colors.grey.dark),
    size: 30,
  };

  /** Renderers */

  render() {
    const { type, ...props } = this.props;
    const Icon = icons[type];
    const link = config.social[type];
    if (!Icon) {
      return null;
    }
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Button>
          <StyledIcon>
            <Icon {...props} color={props.fill || props.stroke} />
          </StyledIcon>
        </Button>
      </a>
    );
  }
}
