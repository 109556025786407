/**
 * Created by Giannis Kallergis on 2019-03-24
 */

import React from "react";

/** Utilities */
import * as config from "../../config/SiteConfig";

export default class Visibility extends React.PureComponent {
  componentDidMount() {
    const currentPath = window.location.pathname;
    const text = `Hooray! Someone is browsing ${currentPath}`;
    const body = JSON.stringify({ text });
    if (process.env.NODE_ENV !== "development") {
      void fetch(config.slackHook, { method: "POST", body });
    }
  }

  render() {
    return <div />;
  }
}
