/**
 * Created by Giannis Kallergis on 2018-12-25
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

/** Utilities */
import config from "../../config/SiteConfig";

/** Setup */
const pathPrefix = config.pathPrefix === "/" ? "" : config.pathPrefix;
const blogURL = config.siteUrl + config.pathPrefix;
const blogBanner = config.siteUrl + pathPrefix + config.siteBanner;

export default class SEO extends React.PureComponent {
  static defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
    isPost: false,
  };

  static propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    slug: PropTypes.string,
    isPost: PropTypes.bool,
    date: PropTypes.string,
  };

  /** Getters / Setters */

  get titleTemplate() {
    const { title } = this.props;
    if (title) {
      return `%s | ${config.siteTitle}`;
    }
    return "%s";
  }

  get title() {
    const { title } = this.props;
    return title || config.siteTitle;
  }

  get description() {
    const { description } = this.props;
    return description || config.siteDescription;
  }

  get image() {
    const { image } = this.props;
    return image || blogBanner;
  }

  get url() {
    const { slug } = this.props;
    if (slug) {
      return config.siteUrl + pathPrefix + slug;
    }
    return blogURL;
  }

  get keywords() {
    const { keywords } = this.props;
    if (keywords.length) {
      return keywords.join(", ");
    }
    return config.siteKeywords;
  }

  get JSONSchema() {
    const { isPost, date } = this.props;
    const title = this.title;
    const description = this.description;
    const image = this.image;
    const url = this.url;
    const schema = {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "@id": url,
      url: url,
      name: title,
      alternateName: config.siteTitleAlt || "",
    };

    if (isPost) {
      schema["@type"] = "BlogPosting";
      schema["headline"] = title;
      schema["image"] = { "@type": "ImageObject", url: image };
      schema["description"] = description;
      schema["datePublished"] = date;
      schema["dateModified"] = date;
      schema["isPartOf"] = blogURL;
      schema["mainEntityOfPage"] = { "@type": "WebSite", "@id": blogURL };
      schema["author"] = { "@type": "Person", name: config.author };
      schema["publisher"] = { "@type": "Person", name: config.author };
    }

    return JSON.stringify([schema]);
  }

  /** Renderers */

  render() {
    let { lang, isPost } = this.props;
    const title = this.title;
    const description = this.description;
    const image = this.image;
    const url = this.url;
    return (
      <Helmet titleTemplate={this.titleTemplate}>
        <html lang={lang || config.siteLanguage} />
        <title>{this.title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* Favicons */}
        <link rel="apple-touch-icon" sizes="180x180" href={config.appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={config.favicon} />
        <link rel="icon" type="image/png" sizes="16x16" href={config.fav16} />
        <link rel="mask-icon" href={config.maskIcon} color={config.themeColor} />
        <meta name="msapplication-TileColor" content={config.themeColor} />

        {/* JSON SCHEMA GOES HERE */}
        <script type="application/ld+json">{this.JSONSchema}</script>

        {/* FACEBOOK SEO */}
        <meta property="og:locale" content={config.ogLanguage} />
        <meta property="og:site_name" content={config.ogSiteName ? config.ogSiteName : ""} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content={isPost ? "article" : "website"} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />

        {/* TWITTER SEO */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={config.userTwitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <meta name="keywords" content={this.keywords} />
      </Helmet>
    );
  }
}
