/**
 * Created by Giannis Kallergis on 2018-12-24
 */

const sizes = {
  tablet: "1200px",
  phone: "600px",
};

export const media = {
  tablet: `(max-width: ${sizes.tablet})`,
  phone: `(max-width: ${sizes.phone})`,
};
