/**
 * Created by Giannis Kallergis on 2018-12-24
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

/** Components */
import SEO from "./SEO";
import SocialIcon from "./SocialIcon";

/** Utilities */
import Theme from "../../config/Theme";
import { media } from "../utils/media";
import Visibility from "./Visibility";

const GlobalStyle = createGlobalStyle`
  ::selection {
    color: ${Theme.colors.bg};
    background: ${Theme.colors.primary};
  }
  body {
    background: ${Theme.colors.bg};
    color: ${Theme.default};
    font-display: swap;
    @media ${media.phone} {
      font-size: 14px;
    }
  }
  a {
    color: ${Theme.colors.primary};
    text-decoration: underline;
    transition: all ${Theme.transitions.normal};
    :hover {
      transform: translateY(-2px);
      text-decoration: none;
    }
  }
  h1, h2, h3, h4 {
    color: ${Theme.colors.grey.dark};
  }
  blockquote {
    font-style: italic;
    position: relative;
  }
  blockquote:before {
    content: "";
    position: absolute;
    background: ${Theme.colors.primary};
    height: 100%;
    width: 6px;
    margin-left: -1.6rem;
  }
  label {
    margin-bottom: .5rem;
    color: ${Theme.colors.grey.dark};
  }
  input, textarea {
    border-radius: .5rem;
    border: none;
    background: rgba(0, 0, 0, 0.05);
    padding: .25rem 1rem;
    &:focus {
      outline: none;
    }
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 3rem 0;
  span {
    font-size: 0.75rem;
  }
`;

const Layout = ({ children, ...props }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
          buildTime(formatString: "D MMM YYYY")
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={Theme}>
        <>
          <SEO
            title={data.site.siteMetadata.title}
            description={data.site.siteMetadata.description}
          />
          <GlobalStyle />
          {children}
          <Footer>
            <div>
              <SocialIcon type={"twitter"} />
              <SocialIcon type={"github"} />
              <SocialIcon type={"linkedIn"} />
              <SocialIcon type={"bitBucket"} />
            </div>
            &copy; 2019 by John A. Kallergis <br />
            <span>Last updated: {data.site.buildTime}</span>
            <Visibility {...props} />
          </Footer>
        </>
      </ThemeProvider>
    )}
  />
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
